<template>
    <div class="editpet">
        <v-row justify="start" align="center" class="pt-5 pl-6 ">
            
                <v-btn icon large class="mb-8" @click="launchPetPhotoPicker" :loading="submitting">
                    <v-icon large color="primary">mdi-image-plus</v-icon>
                </v-btn>
           

            <input type="file" ref="portfoliopic" name="portfoliopic" @change="loadImage($event)" style="display:none" accept="image/png, image/jpg, image/jpeg, video/mp4, video/quicktime">

            <v-dialog v-model="portfolioDialog" width="500" :fullscreen="$vuetify.breakpoint.mobile">
                <v-card color="black">
                    <v-toolbar flat dark color="black">
                        <v-btn icon @click="portfolioDialog = false" color="grey">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn icon color="white" @click="savePic" :loading="submitting" :disabled="submitting">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>

                    </v-toolbar>
                    <v-card-text class="pa-0">
                        <cropper ref="cropper" :src="selectedFile" :canvas="{
                            height: 600,
                            width: 800
                          }" :stencil-props="{
                              aspectRatio: 8/6,
                              movable: true,
                              scalable: true,
                          }" image-restriction="stencil" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import {
  Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';

export default {
  name: 'EditPet',
  props: ['pet'],
  mounted() {
      this.xid = this.pet.xid;
      this.petAvatar = this.pet.avatar ? this.pet.avatar : null;
      this.petPics = this.pet.photos;
      this.petVideos = this.pet.videos;
  },

  data: () => ({

    snackbar: false,
    snackbar_text: '',
    xid: '',
    petPics: [],
    petVideos: [],
    petPicsBlobs: [],
    selectedFile: '',
    portfolioDialog: false,
    portfolioFile: null,

    submitting: false,

    petAvatar: null,
    petAvatarBlob: null,
    avatarDialog: false,
    asubmitting: false,
    avatarFile: null,
    photoDeleteConfirm: false,
    picDeleting: false,
    picToDelete: null,
    videoDeleteConfirm: false,
    videoDeleting: false,
    videoToDelete: null,
  }),

  components: {
    Cropper
  },

  methods: {

    getVideo() {
      return '/img/icons/player.png';
    },

    launchPetPhotoPicker() {
      this.$refs.portfoliopic.click();
    },

    loadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        let uploadedFile=input.files[0];
        if(uploadedFile['type'].split('/')[0] === 'video') {
          let vidsize = (uploadedFile['size'] / (1024*1024)).toFixed(2);
          if (vidsize > 50) {
            this.snackbar_text = "Video size must me less than 50MB.";
            this.snackbar = true;
          } else {
            this.saveVideo(uploadedFile);
          }
        } else {
          var reader = new FileReader();

          reader.onload = (e) => {
            this.selectedFile = e.target.result;
            this.portfolioDialog = true;

          };
          reader.readAsDataURL(input.files[0]);
        }
      }
    },

    saveVideo(video) {
      this.submitting = true;

      let formData = new FormData();

      formData.append('id', this.xid);
      formData.append('video', video);

      this.$store.dispatch('addVideo', formData)
          .then((resp) => {
              this.submitting = false;
              this.$emit('update-user-detail', resp.data)
          })
          .catch(err => {
            this.snackbar_text = "Error while updating";
            this.snackbar = true;
            this.submitting = false;
          });

    },
    savePic() {
      this.submitting = true;
      const {
        canvas
      } = this.$refs.cropper.getResult();
      if (canvas) {

        canvas.toBlob((blob) => {

          let formData = new FormData();

          formData.append('id', this.xid);
          formData.append('photo', blob);

          this.$store.dispatch('addPhoto', formData)
              .then((resp) => {
                  this.portfolioDialog = false;
                  this.submitting = false;
                  this.$emit('update-user-detail', resp.data)
              })
              .catch(err => {
                  this.snackbar_text = "Error while updating";
                  this.snackbar = true;
                  this.submitting = false;
              });

        }, "image/jpeg");

      }
    },
  }
}
</script>

<style scoped>
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>>>fieldset {
  border-color: #adb5bd;
}
</style>