var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editpet"},[_c('v-row',{staticClass:"pt-5 pl-6",attrs:{"justify":"start","align":"center"}},[_c('v-btn',{staticClass:"mb-8",attrs:{"icon":"","large":"","loading":_vm.submitting},on:{"click":_vm.launchPetPhotoPicker}},[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-image-plus")])],1),_c('input',{ref:"portfoliopic",staticStyle:{"display":"none"},attrs:{"type":"file","name":"portfoliopic","accept":"image/png, image/jpg, image/jpeg, video/mp4, video/quicktime"},on:{"change":function($event){return _vm.loadImage($event)}}}),_c('v-dialog',{attrs:{"width":"500","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.portfolioDialog),callback:function ($$v) {_vm.portfolioDialog=$$v},expression:"portfolioDialog"}},[_c('v-card',{attrs:{"color":"black"}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"black"}},[_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.portfolioDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"white","loading":_vm.submitting,"disabled":_vm.submitting},on:{"click":_vm.savePic}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('cropper',{ref:"cropper",attrs:{"src":_vm.selectedFile,"canvas":{
                        height: 600,
                        width: 800
                      },"stencil-props":{
                          aspectRatio: 8/6,
                          movable: true,
                          scalable: true,
                      },"image-restriction":"stencil"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }